import Filters from './api_usage/Filters'
import Chart   from './api_usage/Chart'

export default class ApiUsage extends React.PureComponent

  # Colors generated in Ruby with 'color-generator' gem:
  # ---
  # generator = ColorGenerator.new(:saturation => 0.35, :value => 0.9, :seed => 42)
  # puts 50.times.collect { "##{generator.create_hex}" }.uniq.inspect
  ENGINE_COLORS = [
    "#6ba9e3", # --primary-color-100    -> very similar to the second color of the list (we switched because we prefer blue to be the dominant color)
    "#f189a2", # --secondary-color-100  -> very similar to the first color of the list
    "#c8e595", "#e595df", "#95e5d4", "#e5bd95", "#a695e5", "#9ce595", "#e595b4", "#95cbe5", "#e2e595", "#d195e5", "#95e5ba", "#e5a295", "#95a0e5", "#b7e595", "#e595ce", "#95e5e5", "#e5ce95", "#b695e5", "#95e59f", "#e595a3", "#95bae5", "#d2e595", "#e295e5", "#95e5ca", "#e5b395", "#9b95e5", "#a6e595", "#e595be", "#95d5e5", "#e5de95", "#c795e5", "#95e5af", "#e59895", "#95aae5", "#c1e595", "#e595d9", "#95e5db", "#e5c395", "#ac95e5", "#96e595", "#e595ad", "#95c5e5", "#dce595", "#d795e5", "#95e5c0", "#e5a995", "#9599e5", "#b1e595"
  ]

  constructor: (props) ->
    super(props)

    @state =
      year:                    @props.year
      month:                   @props.month
      visitBreakdowns:         {}               # Monthly breakdown that is loaded async
      filteredVisitBreakdowns: {}               # Breakdown that is filtered by active filters
      chartType:               'billableAmount' # 'charsCount' or 'billableAmount'
      chartData:               [] # formatted for Rechart to draw the chart
      monthlyCharsCount:       0
      monthlyBillableAmount:   0
      apiKeyFilters:           {} # { 'openai4ominiEu' => { 'selected' => false, 'billableAmount': 4.34, charsCount': 4 },
      engineFilters:           {}
      actionFilters:           {}
      engineColors:            {} # { 'openai4ominiEu' => '#e59599', ... }
      loaded:                  false

    @goToPrevMonth         = @goToPrevMonth.bind(this)
    @goToNextMonth         = @goToNextMonth.bind(this)
    @selectCostCharType    = @selectCostCharType.bind(this)
    @selectCharsCharType   = @selectCharsCharType.bind(this)
    @toggleFilter          = @toggleFilter.bind(this)
    @filterSorter          = @filterSorter.bind(this)
    @formatChartValue      = @formatChartValue.bind(this)
    @formatAmount          = @formatAmount.bind(this)
    @formatNumber          = @formatNumber.bind(this)

  componentDidMount: ->
    @bindPopState()

    @reloadVisitBreakdowns( =>
      @setState(loaded: true)
    )

  bindPopState: ->
    # Only triggered when back/forward from browser
    window.onpopstate = (event) =>
      dateFromPath = window.location.pathname.match(/(\d{4})-(\d{1,2})/)

      @setState(
        year:  parseInt(dateFromPath[1])
        month: parseInt(dateFromPath[2])
      , @reloadVisitBreakdowns)

  # Visit breakdowns are hashes with these levels:
  # {
  #   apiKey1: {
  #     20250303: {
  #       openai4oUs: {
  #         alternatives: {
  #           billableAmount: "0.0105",
  #           charsCount: 525
  #         }
  #       }
  #     }
  #   }
  # }
  reloadVisitBreakdowns: (callback) ->
    http.get("/#{@props.currentLocale}/account/api_usages/#{@state.year}-#{@state.month}.json", {}, (data) =>
      visitBreakdowns = data.visitBreakdowns
      completeFilters = @computeFilters(visitBreakdowns)
      engineColors    = @computeEngineColors(completeFilters.engineFilters) # Only on full reloading, don't apply on filtered breakdown

      filteredVisitBreakdowns = @createFilteredVisitBreakdowns(visitBreakdowns)
      monthlyMetrics          = @computeMonthlyMetrics(filteredVisitBreakdowns) # recompute monthlyCharsCount and monthlyBillableAmount

      if monthlyMetrics.monthlyCharsCount == 0 && @hasActiveFilter() # Reset active filters if no data for this month (it happens when changing month in interface while keeping filters!)
        filteredVisitBreakdowns = visitBreakdowns
        monthlyMetrics          = @computeMonthlyMetrics(filteredVisitBreakdowns)

      filters   = @computeFilters(filteredVisitBreakdowns)
      chartData = @computeChartData(filteredVisitBreakdowns)

      newState = Object.assign({ visitBreakdowns: visitBreakdowns, filteredVisitBreakdowns: filteredVisitBreakdowns }, monthlyMetrics, filters, chartData, engineColors)

      @setState(newState, callback)
    )

  createFilteredVisitBreakdowns: (visitBreakdowns) ->
    selectedApiKeys = Object.entries(@state.apiKeyFilters)
                            .filter(([name, values]) -> values.selected)
                            .map(([name, values]) -> name )

    selectedEngines = Object.entries(@state.engineFilters)
                            .filter(([name, values]) -> values.selected)
                            .map(([name, values]) -> name )

    selectedActions = Object.entries(@state.actionFilters)
                            .filter(([name, values]) -> values.selected)
                            .map(([name, values]) -> name )

    filteredVisitBreakdowns = {}

    for apiKey, dateEntries of visitBreakdowns
      if selectedApiKeys.length == 0 || selectedApiKeys.includes(apiKey)
        filteredVisitBreakdowns[apiKey] ||= {}

        for date, engineEntries of dateEntries
          filteredVisitBreakdowns[apiKey][date] ||= {}

          for engine, actionEntries of engineEntries
            if selectedEngines.length == 0 || selectedEngines.includes(engine)
              filteredVisitBreakdowns[apiKey][date][engine] ||= {}

              for action, metricsEntries of actionEntries
                if selectedActions.length == 0 || selectedActions.includes(action)
                  filteredVisitBreakdowns[apiKey][date][engine][action] = visitBreakdowns[apiKey][date][engine][action]

    filteredVisitBreakdowns

  computeMonthlyMetrics: (visitBreakdowns) ->
    monthlyCharsCount     = 0
    monthlyBillableAmount = 0

    for apiKey, dateEntries of visitBreakdowns
      for date, engineEntries of dateEntries
        for engine, actionEntries of engineEntries
          for action, metricsEntries of actionEntries
            monthlyCharsCount     += metricsEntries.charsCount
            monthlyBillableAmount += parseFloat(metricsEntries.billableAmount)

    return {
      monthlyCharsCount:     monthlyCharsCount,
      monthlyBillableAmount: monthlyBillableAmount
    }

  computeFilters: (visitBreakdowns) ->
    apiKeyFilters = {}
    engineFilters = {}
    actionFilters = {}

    for apiKey, dateEntries of visitBreakdowns
      apiKeyFilters[apiKey] ||= {
        selected:       @state.apiKeyFilters[apiKey]?.selected || false
        charsCount:     0
        billableAmount: 0.0
      }

      for date, engineEntries of dateEntries
        for engine, actionEntries of engineEntries
          engineFilters[engine] ||= {
            selected:       @state.engineFilters[engine]?.selected || false
            charsCount:     0
            billableAmount: 0.0
          }

          for action, metricsEntries of actionEntries
            actionFilters[action] ||= {
              selected:       @state.actionFilters[action]?.selected || false
              charsCount:     0
              billableAmount: 0.0
            }

            apiKeyFilters[apiKey]['charsCount'] += metricsEntries.charsCount
            engineFilters[engine]['charsCount'] += metricsEntries.charsCount
            actionFilters[action]['charsCount'] += metricsEntries.charsCount

            apiKeyFilters[apiKey]['billableAmount'] += parseFloat(metricsEntries.billableAmount)
            engineFilters[engine]['billableAmount'] += parseFloat(metricsEntries.billableAmount)
            actionFilters[action]['billableAmount'] += parseFloat(metricsEntries.billableAmount)

    return {
      apiKeyFilters: apiKeyFilters
      engineFilters: engineFilters
      actionFilters: actionFilters
    }

  computeEngineColors: (engineFilters) ->
    # Used engines sorted by popularity
    usedEngines = Object.entries(engineFilters)
                        .sort(@filterSorter)
                        .map(([name, values]) => name)

    engineColors = {}

    usedEngines.forEach((engine, i) ->
      engineColors[engine] = ENGINE_COLORS[i]
    )

    return {
      engineColors: engineColors
    }

  computeChartData: (visitBreakdowns) ->
    monthlyTimestamps = @monthlyTimestamps()

    monthlyDateStrings = monthlyTimestamps.map(@timestampToDateString)

    chartData = monthlyDateStrings.map((dateString, i) =>
      Object.assign(
        { time: monthlyTimestamps[i] },
        @computeChartDataForDay(visitBreakdowns, dateString)
      )
    )

    return {
      chartData: chartData
    }

  computeChartDataForDay: (visitBreakdowns, dateString) ->
    totalPerEngine = {}

    for apiKey, dateEntries of visitBreakdowns
      for date, engineEntries of dateEntries
        if date == dateString
          for engine, actionEntries of engineEntries
            for action, metricsEntries of actionEntries
              totalPerEngine[engine] ||= 0

              if @state.chartType == 'charsCount'
                totalPerEngine[engine] += metricsEntries.charsCount
              else if @state.chartType == 'billableAmount'
                totalPerEngine[engine] += parseFloat(metricsEntries.billableAmount) # BigDecimal value is received as string

    totalPerEngine

  monthlyTimestamps: ->
    monthIndex     = @state.month - 1                         # JS months are 0-indexed (0 = January, 11 = December)
    startTimestamp = Date.UTC(@state.year, monthIndex,     1) # Timestamp of first day of the month
    endTimestamp   = Date.UTC(@state.year, monthIndex + 1, 1) # Timestamp of first day of the next month (will bump year automatically)

    timestamps       = []
    currentTimestamp = startTimestamp

    # Loop through all days until we reach the next month
    while currentTimestamp < endTimestamp
      timestamps.push(currentTimestamp)

      currentDate = new Date(currentTimestamp)
      currentDate = currentDate.setUTCDate(currentDate.getUTCDate() + 1)

      currentTimestamp = currentDate

    timestamps

  prevMonthIsDisabled: ->
    currentMonthStartTimestamp = Date.UTC(@state.year, @state.month - 1, 1)
    userCreationTimestamp      = @props.currentUser.createdAt

    # If current user was created during this month (or after), last month is not relevant
    userCreationTimestamp > currentMonthStartTimestamp

  nextMonthIsDisabled: ->
    nowTimestamp            = Date.now()
    nextMonthStartTimestamp = Date.UTC(@state.year, @state.month, 1)

    # If current date is smaller than the start of next month, next month is not relevant
    nowTimestamp < nextMonthStartTimestamp

  goToPrevMonth: ->
    if !@prevMonthIsDisabled()
      newMonth = @state.month - 1
      newYear  = @state.year

      if newMonth < 1
        newMonth = 12
        newYear = newYear - 1

      @setState(
        month: newMonth
        year:  newYear
      , () =>
        @pushStateInUrl()
        @reloadVisitBreakdowns()
      )

  goToNextMonth: ->
    if !@nextMonthIsDisabled()
      newMonth = @state.month + 1
      newYear  = @state.year

      if newMonth > 12
        newMonth = 1
        newYear  = newYear + 1

      @setState(
        month: newMonth
        year:  newYear
      , () =>
        @pushStateInUrl()
        @reloadVisitBreakdowns()
      )

  pushStateInUrl: ->
    history.pushState({}, '', "/#{@props.currentLocale}/account/api_usages/#{@state.year}-#{@state.month}")

  selectCostCharType: ->
    @setState(chartType: "billableAmount", ->
      @setState(
        @computeChartData(@state.filteredVisitBreakdowns)
      )
    )

  selectCharsCharType: ->
    @setState(chartType: "charsCount", ->
      @setState(
        @computeChartData(@state.filteredVisitBreakdowns)
      )
    )

  hasMonthlyUsage: ->
    @state.monthlyCharsCount > 0 || @hasActiveFilter() # if active filter, there is always some usage.
                                                       # prevent to show blankslate for empty filter!

  hasActiveFilter: ->
    hasSelectedApiKeys = Object.values(@state.apiKeyFilters).some((filter) => filter.selected)
    hasSelectedEngines = Object.values(@state.engineFilters).some((filter) => filter.selected)
    hasSelectedActions = Object.values(@state.actionFilters).some((filter) => filter.selected)

    hasSelectedApiKeys || hasSelectedEngines || hasSelectedActions

  noActiveFilter: ->
    !@hasActiveFilter()

  toggleFilter: (name, value) ->
    updatedFilters = {
      ...@state["#{name}Filters"],
      "#{value}": {
        ...@state["#{name}Filters"][value],
        'selected': !@state["#{name}Filters"][value].selected
      }
    }

    @setState("#{name}Filters": updatedFilters, =>
      filteredVisitBreakdowns = @createFilteredVisitBreakdowns(@state.visitBreakdowns)

      monthlyMetrics = @computeMonthlyMetrics(filteredVisitBreakdowns)
      filters        = @computeFilters(filteredVisitBreakdowns)
      chartData      = @computeChartData(filteredVisitBreakdowns)

      newState = Object.assign({ filteredVisitBreakdowns: filteredVisitBreakdowns }, monthlyMetrics, filters, chartData)

      @setState(newState)
    )

  filterSorter: ([name1, values1], [name2, values2]) ->
    values2[@state.chartType] - values1[@state.chartType]

  formatChartValue: (value) ->
    if @state.chartType == 'billableAmount'
      @formatAmount(value)
    else if @state.chartType == 'charsCount'
      @formatChars(value)

  formatAmount: (number, precision = 2, smallLimit = 0.01) ->
    formatter = new Intl.NumberFormat(@props.currentLocale, {
      style:                 'currency'
      currency:              'EUR'
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    })

    if number < smallLimit && number != 0
      "<#{formatter.format(0.01)}"
    else
      formatter.format(number)

  formatChars: (number) ->
    if number >= 1000000 # 1_000_000  = 1M (EN) or 1 m (FR)
      letter = @props.i18n.activity.unit.m
    else if number >= 1000 # 1_000 = 1K (EN) or 1 k (FR)
      letter = @props.i18n.activity.unit.k
    else
      letter = ''

    if number < 1000
      number # 967 chars
    else
      number = number / 1000                  # to get K units
      number = number / 1000 if number > 1000 # to get M units

      if number < 1000
        number = @formatNumber(number, { maximumSignificantDigits: 3 }) # 123K/M or 12.3K/M or 1.23K/M
      else
        number = @formatNumber(number, { maximumFractionDigits: 0 })    # 1000M and more without fractions

    "#{number}#{letter}" # 100K or 100M

  formatNumber: (number, options) ->
    new Intl.NumberFormat(@props.currentLocale, options).format(number)

  # toISOString always returns date in UTC
  timestampToDateString: (timestamp) ->
    new Date(timestamp).toISOString().slice(0, 10).replace(/-/g, '')

  formattedCurrentMonth: ->
    date = new Date(@state.year, @state.month - 1, 2)

    new Intl.DateTimeFormat(@props.currentLocale, {
      year:  'numeric',
      month: 'long'
    }).format(date)

  render: ->
    [
      @renderTitle(),
      @renderContent()
    ]

  renderTitle: ->
    <div className="title row" key="title">
      <h1 className="col-5">
        { @props.title }
        { @renderChartTypeButtons() }
      </h1>

      <div className="col-7">
        { @renderPeriod() }
      </div>
    </div>

  renderContent: ->
    if @state.loaded
      if @hasMonthlyUsage()
        <div className="container-full" key="content">
          <div className="row">
            <div className="col-4 col-filters">
              <Filters i18n={@props.i18n}
                       apiKeyFilters={@state.apiKeyFilters}
                       engineFilters={@state.engineFilters}
                       actionFilters={@state.actionFilters}
                       apiKeys={@props.apiKeys}
                       engines={@props.engines}
                       chartType={@state.chartType}
                       engineColors={@state.engineColors}
                       formatValue={@formatChartValue}
                       formatAmount={@formatAmount}
                       formatNumber={@formatNumber}
                       filterSorter={@filterSorter}
                       toggleFilter={@toggleFilter} />
            </div>
            <div className="col-8 col-chart">
              <Chart currentLocale={@props.currentLocale}
                     chartData={@state.chartData}
                     engines={@props.engines}
                     engineFilters={@state.engineFilters}
                     engineColors={@state.engineColors}
                     formatValue={@formatChartValue} />
              { @renderTotal() }
            </div>
          </div>
        </div>
      else
        <div className="monthly-blank-slate" key="content">
          { @props.i18n.blankSlate.noMonthlyUsage }
        </div>

  renderChartTypeButtons: ->
    costButtonClasses  = "btn btn-sm btn-cost"
    charsButtonClasses = "btn btn-sm btn-chars"

    costButtonClasses  += " active" if @state.chartType == 'billableAmount'
    charsButtonClasses += " active" if @state.chartType == 'charsCount'

    <div className="chart-type btn-group">
      <button className={costButtonClasses} onClick={@selectCostCharType}>{@props.i18n.chartType.cost}</button>
      <button className={charsButtonClasses} onClick={@selectCharsCharType}>{@props.i18n.chartType.activity}</button>
    </div>

  renderPeriod: ->
    prevMonthClasses = "prev"
    prevMonthClasses += " disabled" if @prevMonthIsDisabled()

    nextMonthClasses = "next"
    nextMonthClasses += " disabled" if @nextMonthIsDisabled()

    <div className="period">
      <div className={prevMonthClasses} onClick={@goToPrevMonth}>
        <i className="fas fa-angle-left"></i>
      </div>

      <div className="current-month">
        { @formattedCurrentMonth() }
      </div>

      <div className={nextMonthClasses} onClick={@goToNextMonth}>
        <i className="fas fa-angle-right"></i>
      </div>
    </div>

  renderTotal: ->
    if @noActiveFilter()
      totalTitle = @props.i18n.total.complete
    else
      totalTitle = @props.i18n.total.filtered

    if @state.chartType == 'billableAmount'
      formattedTotal =  @formatChartValue(@state.monthlyBillableAmount)
      totalSentence  = "#{totalTitle} #{formattedTotal}"
    else if @state.chartType == 'charsCount'
      formattedTotal =  @formatChartValue(@state.monthlyCharsCount)
      totalSentence  = "#{totalTitle} #{formattedTotal} #{@props.i18n.activity.name}"

    <div className="monthly-total">
      { totalSentence }
    </div>

export default class Chart extends React.PureComponent

  constructor: (props) ->
    super(props)

    @xAxisTickFormatter    = @xAxisTickFormatter.bind(this)
    @yAxisTickFormatter    = @yAxisTickFormatter.bind(this)
    @tooltipLabelFormatter = @tooltipLabelFormatter.bind(this)
    @tooltipFormatter      = @tooltipFormatter.bind(this)
    @tooltipItemSorter     = @tooltipItemSorter.bind(this)
    @renderTooltipWrapper  = @renderTooltipWrapper.bind(this)

  # To always have equidistant ticks on charts
  generateTicks: ->
    length = @props.chartData.length

    return [
      @props.chartData[0].time
      @props.chartData[Math.round(length / 4)].time
      @props.chartData[Math.round(length / 2)].time
      @props.chartData[Math.round(length * 3 / 4)].time
      @props.chartData[length - 1].time
    ]

  xAxisTickFormatter: (timestamp) ->
    new Date(timestamp).toLocaleString(@props.currentLocale, { day: '2-digit', month: 'short' })

  yAxisTickFormatter: (value) ->
    @props.formatValue(value)

  tooltipLabelFormatter: (timestamp) ->
    new Date(timestamp).toLocaleString(@props.currentLocale, { day: '2-digit', month: 'long', year: 'numeric' })

  tooltipFormatter: (value, name, props) ->
    [@props.formatValue(value), @props.engines[name]]

  tooltipItemSorter: (item) ->
    -item.value

  render: ->
    rc = global.Recharts

    # chartData = [
    #   {
    #     time: time
    #     openai4ominiEu: value
    #     openai4oEu: value
    #   },
    #   ...
    # ]

    <div style={{ height: 460 }}>
      <rc.ResponsiveContainer width="100%" height="100%">
        <rc.BarChart data={@props.chartData}
                     margin={{ top: 0, right: 30, bottom: 0, left: 0 }}>
          <rc.CartesianGrid strokeDasharray="2 7"
                            vertical={false} />
          <rc.XAxis type="number"
                    dataKey="time"
                    scale="time"
                    padding={{ left: 18, right: 18 }}
                    domain={['dataMin', 'dataMax']}
                    ticks={@generateTicks()}
                    tickFormatter={@xAxisTickFormatter} />
          <rc.YAxis tickFormatter={@yAxisTickFormatter} />
          <rc.Tooltip labelFormatter={@tooltipLabelFormatter}
                      formatter={@tooltipFormatter}
                      itemSorter={@tooltipItemSorter}
                      content={@renderTooltipWrapper} />
          { @renderChartBars() }
        </rc.BarChart>
      </rc.ResponsiveContainer>
    </div>

  renderChartBars: ->
    rc = global.Recharts

    Object.keys(@props.engineFilters).map((engine, i) =>
      fillColor = @props.engineColors[engine]

      <rc.Bar dataKey={engine} stackId="a" fill={fillColor} key={engine} />
    )

  renderTooltipWrapper: (params) ->
    { payload, label, labelFormatter } = params

    finalLabel = labelFormatter(label, payload)
    total      = _.sumBy(payload, (entry) -> entry.value)
    total      = @props.formatValue(total)

    <div className="recharts-default-tooltip">
      <div className="recharts-tooltip-label">
        <div className="left-part">
          { finalLabel }
        </div>
        <div className="right-part">
          { total }
        </div>
      </div>

      {@renderTooltip(params)}
    </div>

  renderTooltip: (params) ->
    { active, payload, label, itemSorter, formatter, labelFormatter, separator } = params

    if active && payload && payload.length
      items = _.sortBy(payload, itemSorter).map((entry, i) =>
        formatted = formatter(entry.value, entry.name, entry, i, payload)

        [finalValue, finalName] = formatted

        <li className="recharts-tooltip-item" key={"tooltip-item-#{i}"}>
          <div className="left-part">
            <span className="color-square" style={{ backgroundColor: entry.color }}></span>
            <span className="recharts-tooltip-item-name">{finalName}</span>
          </div>
          <div className="right-part">
            <span className="recharts-tooltip-item-value">{finalValue}</span>
          </div>
        </li>
      )

      <ul className="recharts-tooltip-item-list">
        {items}
      </ul>

export default class Filters extends React.PureComponent

  formatTitle: (value) ->
    if @props.chartType == 'billableAmount'
      @props.formatAmount(value, 4, 0.0001)
    else if @props.chartType == 'charsCount'
      "#{@props.formatNumber(value)} #{@props.i18n.activity.name}"

  render: ->
    <>
      <div className="filter-label">
        Api Keys
      </div>
      <ul className="filters filters-api-key">
        { @renderApiKeyFilters() }
      </ul>

      <div className="filter-label">
        Engines
      </div>
      <ul className="filters filters-engine">
        { @renderEngineFilters() }
      </ul>

      <div className="filter-label">
        Actions
      </div>
      <ul className="filters filters-action">
        { @renderActionFilters() }
      </ul>
    </>

  renderApiKeyFilters: ->
    Object.entries(@props.apiKeyFilters)
          .sort(@props.filterSorter)
          .map(([name, values]) => @renderApiKeyFilter(name, values))

  renderEngineFilters: ->
    Object.entries(@props.engineFilters)
          .sort(@props.filterSorter)
          .map(([name, values]) => @renderEngineFilter(name, values))

  renderActionFilters: ->
    Object.entries(@props.actionFilters)
          .sort(@props.filterSorter)
          .map(([name, values]) => @renderActionFilter(name, values))

  renderApiKeyFilter: (name, values) ->
    console.log(name, values)

    value      = values[@props.chartType]
    isSelected = values.selected

    filterClass = "filter"
    filterClass += " selected" if isSelected

    if name.startsWith('apiKey')
      apiKeyId      = parseInt(name.replace('apiKey', ''))
      formattedName = @props.apiKeys[apiKeyId]
    else
      formattedName = name

    formattedValue = @props.formatValue(value)
    formattedTitle = @formatTitle(value)

    if value > 0 || isSelected
      <li className={filterClass}
          key={name}
          onClick={@props.toggleFilter.bind(this, 'apiKey', name)}>
        <span title={formattedName }>
          { formattedName }
        </span>
        <span className="badge"
              title={formattedTitle}>
          { formattedValue }
        </span>
      </li>

  renderEngineFilter: (name, values) ->
    value      = values[@props.chartType]
    isSelected = values.selected

    filterClass = "filter"
    filterClass += " selected" if isSelected

    formattedName  = @props.engines[name]
    formattedValue = @props.formatValue(value)
    formattedTitle = @formatTitle(value)

    if value > 0 || isSelected
      <li className={filterClass}
          key={name}
          onClick={@props.toggleFilter.bind(this, 'engine', name)}>
        { formattedName }
        <span className="color-square"
              style={{ backgroundColor: @props.engineColors[name] }}>
        </span>
        <span className="badge"
              title={formattedTitle}>
          { formattedValue }
        </span>
      </li>

  renderActionFilter: (name, values) ->
    value      = values[@props.chartType]
    isSelected = values.selected

    filterClass = "filter"
    filterClass += " selected" if isSelected

    if name == 'apiTranslate'
      formattedName = <code>/translate</code>
    else if name == 'detectLanguage'
      formattedName = <code>/detect_language</code>
    else
      formattedName = name

    formattedValue = @props.formatValue(value)
    formattedTitle = @formatTitle(value)

    if value > 0 || isSelected
      <li className={filterClass}
          key={name}
          onClick={@props.toggleFilter.bind(this, 'action', name)}>
        { formattedName }
        <span className="badge"
              title={formattedTitle}>
          { formattedValue }
        </span>
      </li>
